import React, { useState, useRef, useEffect } from "react";
import Layout from "../../components/Layout";
import { ImageConstants } from "../../constants/Imagconstants";
import { workSlider, homeSkillSlider } from "../../constants/swiperConstants";
import { Swiper, SwiperSlide } from "swiper/react";

const Salesforce = ({ location }) => {
  const [tab, setTab] = useState("salesforce");
  const scrollTo = useRef(null);
  useEffect(() => {
    setTab(location?.hash.replace("#", "") || "salesforce");
    scrollTo &&
      location?.hash !== "" &&
      scrollTo?.current?.scrollIntoView({
        behavior: "smooth",
      });
  }, [location?.hash]);
  return (
    <Layout title={"Hire salesforce developers in Luxembourg | Razrtech"}>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="subpageheader pb-5">
                  <h1>Salesforce Developers</h1>
                  <p>
                    We provide services like designing, coding and implementing
                    Salesforce applications. Creating timelines and development
                    goals. Testing the stability and functionality of the
                    application. Troubleshooting and fixing bugs.
                  </p>
                  <div className="hire-bg-1">
                    <img
                      loading="lazy"
                      src={ImageConstants.backendIcon}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-2">
                    <img
                      loading="lazy"
                      src={ImageConstants.backend2Icon}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-3">
                    <img
                      loading="lazy"
                      src={ImageConstants.remoteBg4}
                      alt="design"
                    />
                  </div>
                  <div className="backend-bg-1">
                    <img
                      loading="lazy"
                      src={ImageConstants.backend3Icon}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-4">
                    <img
                      loading="lazy"
                      src={ImageConstants.designBg1}
                      alt="design"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="price-tabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        ref={tab === "salesforce" ? scrollTo : null}
                        className="nav-link active"
                        id="pills-Salesforce-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-Salesforce"
                        type="button"
                        role="tab"
                        aria-controls="pills-Salesforce"
                        aria-selected="true"
                      >
                        Salesforce Developers
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active "
              id="pills-Salesforce"
              role="tabpanel"
              aria-labelledby="pills-Salesforce-tab"
            >
              <section>
                <div className="container">
                  <div className="what-bg">
                    <div className="row  mobileflexreverse">
                      <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12">
                        <div className="what-we-do-main">
                          <h2 className="commantitel">
                            Salesforce Developers, ready to join your team
                          </h2>
                          <p className="commanp">
                            RazrTech has a leading team of Salesforce Developers
                            who cater to all your services. Our Salesforce
                            Developers are committed to delivering solutions
                            that match your requirements and add value to your
                            business.
                          </p>
                          <a
                            href="https://form.jotform.com/222352612041340"
                            target="_blank"
                            className="btn btn-primary mt-5"
                          >
                            Get Salesforce Developers
                          </a>
                        </div>
                      </div>
                      <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12">
                        <div className="what-we-do">
                          <figure>
                            <img
                              loading="lazy"
                              src={ImageConstants.rubybanner}
                              alt="ruby-banner"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="sub-width-60 mb-5">
                        <h2 className="commantitel">Skills & Experience</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 align-item-center mobileflexreverse">
                    <div className="col-lg-6">
                      <div className="skill-div">
                        <div className="developer-line">
                          <div className="line-one">
                            <div className="tick-icon tick-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                              >
                                <g
                                  id="Group_18714"
                                  data-name="Group 18714"
                                  transform="translate(-141 -2995)"
                                >
                                  <g
                                    id="Group_16358"
                                    data-name="Group 16358"
                                    transform="translate(0 1259)"
                                  >
                                    <g
                                      id="Rectangle_4002"
                                      data-name="Rectangle 4002"
                                      transform="translate(141 1736)"
                                      fill="#fff"
                                      stroke="#e4e4e4"
                                      strokeWidth="3"
                                    >
                                      <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        stroke="none"
                                      />
                                      <rect
                                        x="1.5"
                                        y="1.5"
                                        width="31"
                                        height="31"
                                        rx="15.5"
                                        fill="none"
                                      />
                                    </g>
                                    <g
                                      id="tick"
                                      transform="translate(150.64 1748.177)"
                                    >
                                      <path
                                        id="Path_71211"
                                        data-name="Path 71211"
                                        d="M15.44,66.6a.958.958,0,0,0-1.356,0L5.032,75.654l-3.4-3.4A.959.959,0,1,0,.281,73.614l4.074,4.074a.958.958,0,0,0,1.356,0l9.73-9.73A.96.96,0,0,0,15.44,66.6Z"
                                        transform="translate(0 -66.32)"
                                        fill="#e4e4e4"
                                        stroke="#e4e4e4"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="tick-line">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="3"
                                height="150"
                                viewBox="0 0 3 225"
                              >
                                <line
                                  id="Line_240"
                                  data-name="Line 240"
                                  y2="225"
                                  transform="translate(1.5)"
                                  fill="none"
                                  stroke="#e4e4e4"
                                  strokeWidth="3"
                                  stroke-dasharray="12"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="line-one">
                            <div className="tick-icon tick-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                              >
                                <g
                                  id="Group_18714"
                                  data-name="Group 18714"
                                  transform="translate(-141 -2995)"
                                >
                                  <g
                                    id="Group_16358"
                                    data-name="Group 16358"
                                    transform="translate(0 1259)"
                                  >
                                    <g
                                      id="Rectangle_4002"
                                      data-name="Rectangle 4002"
                                      transform="translate(141 1736)"
                                      fill="#fff"
                                      stroke="#e4e4e4"
                                      strokeWidth="3"
                                    >
                                      <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        stroke="none"
                                      />
                                      <rect
                                        x="1.5"
                                        y="1.5"
                                        width="31"
                                        height="31"
                                        rx="15.5"
                                        fill="none"
                                      />
                                    </g>
                                    <g
                                      id="tick"
                                      transform="translate(150.64 1748.177)"
                                    >
                                      <path
                                        id="Path_71211"
                                        data-name="Path 71211"
                                        d="M15.44,66.6a.958.958,0,0,0-1.356,0L5.032,75.654l-3.4-3.4A.959.959,0,1,0,.281,73.614l4.074,4.074a.958.958,0,0,0,1.356,0l9.73-9.73A.96.96,0,0,0,15.44,66.6Z"
                                        transform="translate(0 -66.32)"
                                        fill="#e4e4e4"
                                        stroke="#e4e4e4"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="tick-line">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="3"
                                height="150"
                                viewBox="0 0 3 225"
                              >
                                <line
                                  id="Line_240"
                                  data-name="Line 240"
                                  y2="225"
                                  transform="translate(1.5)"
                                  fill="none"
                                  stroke="#e4e4e4"
                                  strokeWidth="3"
                                  stroke-dasharray="12"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="line-one">
                            <div className="tick-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                              >
                                <g
                                  id="Group_18714"
                                  data-name="Group 18714"
                                  transform="translate(-141 -2995)"
                                >
                                  <g
                                    id="Group_16358"
                                    data-name="Group 16358"
                                    transform="translate(0 1259)"
                                  >
                                    <g
                                      id="Rectangle_4002"
                                      data-name="Rectangle 4002"
                                      transform="translate(141 1736)"
                                      fill="#fff"
                                      stroke="#e4e4e4"
                                      strokeWidth="3"
                                    >
                                      <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        stroke="none"
                                      />
                                      <rect
                                        x="1.5"
                                        y="1.5"
                                        width="31"
                                        height="31"
                                        rx="15.5"
                                        fill="none"
                                      />
                                    </g>
                                    <g
                                      id="tick"
                                      transform="translate(150.64 1748.177)"
                                    >
                                      <path
                                        id="Path_71211"
                                        data-name="Path 71211"
                                        d="M15.44,66.6a.958.958,0,0,0-1.356,0L5.032,75.654l-3.4-3.4A.959.959,0,1,0,.281,73.614l4.074,4.074a.958.958,0,0,0,1.356,0l9.73-9.73A.96.96,0,0,0,15.44,66.6Z"
                                        transform="translate(0 -66.32)"
                                        fill="#e4e4e4"
                                        stroke="#e4e4e4"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="tick-line">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="3"
                                height="150"
                                viewBox="0 0 3 225"
                              >
                                <line
                                  id="Line_240"
                                  data-name="Line 240"
                                  y2="225"
                                  transform="translate(1.5)"
                                  fill="none"
                                  stroke="#e4e4e4"
                                  strokeWidth="3"
                                  stroke-dasharray="12"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="line-one">
                            <div className="tick-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                              >
                                <g
                                  id="Group_18714"
                                  data-name="Group 18714"
                                  transform="translate(-141 -2995)"
                                >
                                  <g
                                    id="Group_16358"
                                    data-name="Group 16358"
                                    transform="translate(0 1259)"
                                  >
                                    <g
                                      id="Rectangle_4002"
                                      data-name="Rectangle 4002"
                                      transform="translate(141 1736)"
                                      fill="#fff"
                                      stroke="#e4e4e4"
                                      strokeWidth="3"
                                    >
                                      <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        stroke="none"
                                      />
                                      <rect
                                        x="1.5"
                                        y="1.5"
                                        width="31"
                                        height="31"
                                        rx="15.5"
                                        fill="none"
                                      />
                                    </g>
                                    <g
                                      id="tick"
                                      transform="translate(150.64 1748.177)"
                                    >
                                      <path
                                        id="Path_71211"
                                        data-name="Path 71211"
                                        d="M15.44,66.6a.958.958,0,0,0-1.356,0L5.032,75.654l-3.4-3.4A.959.959,0,1,0,.281,73.614l4.074,4.074a.958.958,0,0,0,1.356,0l9.73-9.73A.96.96,0,0,0,15.44,66.6Z"
                                        transform="translate(0 -66.32)"
                                        fill="#e4e4e4"
                                        stroke="#e4e4e4"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="tick-line">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="3"
                                height="150"
                                viewBox="0 0 3 225"
                              >
                                <line
                                  id="Line_240"
                                  data-name="Line 240"
                                  y2="225"
                                  transform="translate(1.5)"
                                  fill="none"
                                  stroke="#e4e4e4"
                                  strokeWidth="3"
                                  stroke-dasharray="12"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="line-one">
                            <div className="tick-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                              >
                                <g
                                  id="Group_18714"
                                  data-name="Group 18714"
                                  transform="translate(-141 -2995)"
                                >
                                  <g
                                    id="Group_16358"
                                    data-name="Group 16358"
                                    transform="translate(0 1259)"
                                  >
                                    <g
                                      id="Rectangle_4002"
                                      data-name="Rectangle 4002"
                                      transform="translate(141 1736)"
                                      fill="#fff"
                                      stroke="#e4e4e4"
                                      strokeWidth="3"
                                    >
                                      <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        stroke="none"
                                      />
                                      <rect
                                        x="1.5"
                                        y="1.5"
                                        width="31"
                                        height="31"
                                        rx="15.5"
                                        fill="none"
                                      />
                                    </g>
                                    <g
                                      id="tick"
                                      transform="translate(150.64 1748.177)"
                                    >
                                      <path
                                        id="Path_71211"
                                        data-name="Path 71211"
                                        d="M15.44,66.6a.958.958,0,0,0-1.356,0L5.032,75.654l-3.4-3.4A.959.959,0,1,0,.281,73.614l4.074,4.074a.958.958,0,0,0,1.356,0l9.73-9.73A.96.96,0,0,0,15.44,66.6Z"
                                        transform="translate(0 -66.32)"
                                        fill="#e4e4e4"
                                        stroke="#e4e4e4"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="sill-box">
                          <div className="skill-1-box skill-yellow-box">
                            <h5>Salesforce Technologies</h5>
                            <p>
                              Expertise in Pardot, Mulesoft, Lightning,
                              Salesforce Cloud, Commerce Cloud & more.
                            </p>
                          </div>
                          <div className="skill-1-box skill-pink-box">
                            <h5>Salesforce Architecture</h5>
                            <p>
                              Hands-on experience in administration setup for
                              managing reports
                            </p>
                          </div>
                          <div className="skill-1-box skill-blue-box">
                            <h5>Salesforce Applications</h5>
                            <p>
                              Implement the application with apex development
                              and visualforce page development
                            </p>
                          </div>
                          <div className="skill-1-box skill-pink-box">
                            <h5>Monitor</h5>
                            <p>
                              Developing client relationships for identifying
                              bugs and aligning with requirements during
                              debugging.
                            </p>
                          </div>
                          <div className="skill-1-box skill-blue-box">
                            <h5>Experience</h5>
                            <p>
                              Proficiency in all trending Salesforce tools.
                              Stick to timelines and precision.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="devloper-img">
                        <figure>
                          <img
                            loading="lazy"
                            src={ImageConstants.rubbyRight}
                            alt="developer"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sub-width-60 mb-5">
                  <h2 className="commantitel">How It Works</h2>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Swiper className="workslider" {...workSlider}>
              <div className="swiper-wrapper">
                <SwiperSlide>
                  <div className="work-main work-main-pink border-radius-left-top">
                    <div className="backend-developer-slide">
                      <p className="num">1</p>
                      <figure>
                        <img
                          loading="lazy"
                          src={ImageConstants.devSliderIcon1}
                          alt="slider-icon"
                        />
                      </figure>
                      <p className="sendp">Send Us Your Business Requirement</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="work-main work-main-sky">
                    <div className="backend-developer-slide">
                      <p className="num">2</p>
                      <figure>
                        <img
                          loading="lazy"
                          src={ImageConstants.devSliderIcon2}
                          alt="slider-icon"
                        />
                      </figure>
                      <p className="sendp">We Find The Right Fit For You</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="work-main  work-main-yellow ">
                    <div className="backend-developer-slide">
                      <p className="num">3</p>
                      <figure>
                        <img
                          loading="lazy"
                          src={ImageConstants.devSliderIcon3}
                          alt="slider-icon"
                        />
                      </figure>
                      <p className="sendp">Run Interviews And Tests</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="work-main  work-main-ppt border-radius-right-bottom">
                    <div className="backend-developer-slide">
                      <p className="num">4</p>
                      <figure>
                        <img
                          loading="lazy"
                          src={ImageConstants.devSliderIcon4}
                          alt="slider-icon"
                        />
                      </figure>
                      <p className="sendp">Test To Hire In Less Than 15 Days</p>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
              <div className="swiper-button-next">
                <img loading="lazy" src={ImageConstants.nextArrow} alt="next" />
              </div>
              <div className="swiper-button-prev">
                <img loading="lazy" src={ImageConstants.preArrow} alt="Prev" />
              </div>
            </Swiper>
          </div>
          <section>
            <div className="container mt-5">
              <div className="row">
                <div className="col-12 mb-5 mt-5">
                  <div className="graph">
                    <h2 className="commantitel">Why Outsource</h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mobilescolldiv">
                <div className="col-lg-6 col-md-12">
                  <div className="razrfeaturebox Outsource-box">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.outsourcedIcon1}
                        alt="effective"
                      />
                    </figure>
                    <h5>Low costs, high efficiency</h5>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="razrfeaturebox Outsource-box">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.outsourcedIcon2}
                        alt="effective"
                      />
                    </figure>
                    <h5>Fast Response Times</h5>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="razrfeaturebox Outsource-box">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.outsourcedIcon3}
                        alt="effective"
                      />
                    </figure>
                    <h5>Specialist Expertise</h5>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="razrfeaturebox Outsource-box Outsource-box-4">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.outsourcedIcon4}
                        alt="effective"
                      />
                    </figure>
                    <h5>Compliance & Security assurance</h5>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="razrfeaturebox Outsource-box Outsource-box-5">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.outsourcedIcon5}
                        alt="effective"
                      />
                    </figure>
                    <h5>Strategic approach</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="skill-home">
                    <h2 className="commantitel">Our Trusted Clients</h2>
                  </div>
                </div>
              </div>
            </div>
            <Swiper className="homeskillslider" {...homeSkillSlider}>
              <div className="swiper-wrapper">
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientCastrol}
                      alt="castrol"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientToi}
                      alt="toi"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientGoeres}
                      alt="goeres"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientFirstpost}
                      alt="firstpost"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientLt}
                      alt="l-t"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientFlipkart}
                      alt="flipkart"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientLatestly}
                      alt="latestly"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientTeamup}
                      alt="teamup"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientIronman}
                      alt="ironman"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.fiveRing}
                      alt="5_Ring"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.twoImirates}
                      alt="2_Emirates"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.threeMgs}
                      alt="3_MG"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.sevenGMC}
                      alt="7_GMC"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.sevenChevrolet}
                      alt="7_Chevrolet"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.oneIsuzu}
                      alt="1_Isuzu"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.sixRoyalEnfield}
                      alt="6_Royal-Enfield"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.fourLoreal}
                      alt="4_Loreal"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.nineBp}
                      alt="9_bp"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientVodafone}
                      alt="client-vodafone"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientGeMoney}
                      alt="client-ge-money"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientAudi}
                      alt="client-audi"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientArcelor}
                      alt="client-arcelor"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientTomorrowStreet}
                      alt="tomorrow-street"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientCna}
                      alt="client-cna"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientTechnoport}
                      alt="client-technoport"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientChronicle}
                      alt="client-chronicle"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientEy}
                      alt="client-ey"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientMpay}
                      alt="client-mpay"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img
                      loading="lazy"
                      src={ImageConstants.clientWikiStage}
                      alt="client-WikiStage"
                    />
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
          </section>
          <section>
            <div className="container">
              <div className="letstalksection">
                <div className="row align-items-center">
                  <div className="col-lg-9 col-md-8">
                    <div className="talktext">
                      <h2 className="commantitel">
                        Looking for highly skilled <br /> resources for your
                        Project?
                      </h2>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="text-center">
                      <a
                        href="https://form.jotform.com/222352612041340"
                        target="_blank"
                        className="btn btn-primary"
                      >
                        Get in touch
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </main>
    </Layout>
  );
};

export default Salesforce;
